import React, { useEffect, useRef, useState } from "react";
import SearchAdminGenericSection from "./searchAdminGenericSection";
import "./adminGenericSection.scss";
import SearchAdminContactInfos from "./searchAdminContactInfos";
import { useGenericSection } from "../../services/genericSection.service";
import {FaChevronRight, FaPlus, FaSave, FaTimes, FaTimesCircle} from "react-icons/fa";
import { TitleAdmin } from "../components/titleAdmin/titleAdmin";
import { useHistory } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { HeadingAdmin } from "../components/headingAdmin/headingAdmin";
import {Button, Col, Divider, Flex, Form, Input, Row, Select} from "antd";
import { Checkbox } from "../components/checkbox/checkbox";
import Swal from "sweetalert2";
import { InputFile } from "../components/inputFile/inputFile";
import {MdCleaningServices} from "react-icons/md";
import {TbReload} from "react-icons/tb";
import {IoReloadOutline} from "react-icons/io5";
import {RiLoopLeftFill} from "react-icons/ri";

export const AdminGenericSection = () => {
  const [templateShow, setTemplateShow] = useState("1");
  const [localfiles, setLocalFiles] = useState([]);
  const [sections, setSections] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);
  const [addSocialMedia, setAddSocialMedia] = useState(false);
  const [socialMedia, setSocialMedia] = useState([]);
  const gridRef = useRef();
  const [genericItem, setGenericItem] = useState({
    genericSectionItemId: 0,
    title: "",
    subtitle: "",
    companyId: 0,
    genericSectionTypeId: 0,
    genericSectionName: "",
  });
  const [socialInfo, setSocialInfo] = useState({
    genericSectionItemId: 0,
    contactTypeId: 0,
    contactTypeName: "",
    contactValue: "",
    mainContact: false,
    showValue: false,
  });
  const history = useHistory();
  const [localFilesUpdated, setLocalFilesUpdated] = useState(false);
  const [filesSaved, setFilesSaved] = useState("");
  const [formSocialMedia] = Form.useForm();
  const [formItem] = Form.useForm();
  const [currentItemSocialMedia, setCurrentItemSocialMedia] = useState(0);
  const [btnMainContact, setBtnMainContact] = useState(false);

  const toggleTemplate = (sec) => setTemplateShow(sec);

  const clearObjSocialInfo = () =>
    setSocialInfo({
      genericContactInfoId: 0,
      genericSectionItemId: 0,
      contactTypeId: 0,
      contactTypeName: "",
      contactValue: "",
      mainContact: false,
      showValue: false,
    });

  const clearObjGenericItem = () =>
    setGenericItem({
      title: "",
      subtitle: "",
      companyId: 0,
      genericSectionTypeId: 0,
      genericSectionName: "",
      genericSectionItemId: 0,
    });

  async function saveGenericItem(item) {
    useGenericSection.createGenericSectionItem(item, localfiles).then((res) => {
      setGenericItem(res);
      useGenericSection
        .getGenericItemImageById(res.genericSectionItemId)
        .then((result) => {
          if (result.files.length > 0) {
            const file = result.files[0].file;
            setLocalFiles([file]);
          }
        });
    });
  }

  async function saveSocialInfo(items) {
    useGenericSection.createContactInfos(items).then((res) => {
      toggleTemplate("1");
      gridRef.current.refreshDataGrid();
      clearObjSocialInfo();
      clearObjGenericItem();
      setSocialMedia([]);
      setLocalFiles([]);
    });
  }

  async function updateGenericItem(item) {
    if (localfiles.length === 0) {
      return Swal.fire({
        text: "La imagen de la publicación es requerida",
        icon: "error",
      });
    }

    if (!localFilesUpdated) {
      const file = new File(
        [filesSaved],
        `img_${item.title + item.genericSectionItemId}.png`,
        {
          type: "image/png",
        }
      );
      setLocalFiles([file]);
    }

    useGenericSection
      .updateGenericSectionItem(item, localfiles)
      .then((result) => {
        if (result.genericSectionItemId) {
          toggleTemplate("1");
          gridRef.current.refreshDataGrid();
          setLocalFiles([]);
          return Swal.fire({
            text: "Publicación actualizada con éxito",
            icon: "success",
          });
        } else {
          return Swal.fire({
            text: "Algo sucedió, los cambios no fueron guardados",
            icon: "error",
          });
        }
      });
  }

  const handleSubmitSocialMedia = async () => {

    if (socialMedia.length <= 0 && genericItem.genericSectionItemId > 0) {
      return Swal.fire({
        title: "¡Upss!",
        text: "La publicación no tiene opciones de contacto",
        icon: "error",
      });
    }

    if (socialMedia.length > 0) {
      const thereIsMain = socialMedia.filter((c) => c.mainContact);
      if (thereIsMain.length === 0) {
        return Swal.fire({
          title: "Ups...",
          text: "Es necesario un contacto principal en la lista de redes sociales y enlaces.",
          icon: "error",
        });
      }
    }

    if (socialMedia.length > 0) {
      const itemsToSave = socialMedia.map((c) => ({
        ...c,
        genericSectionItemId: genericItem.genericSectionItemId,
      }));
      await saveSocialInfo(itemsToSave).then((res) => Swal.fire({
        text: "Tu lista de contactos se actualizó exitosamente",
        icon: "success",
      }));
      cleanFormSocialMedia()
    }
  }

  const handleSubmit = async () => {
    if (localfiles.length === 0) {
      return Swal.fire({
        text: "La imagen de la publicación es requerida",
        icon: "error",
      });
    }

    const itemSave = {
      genericSectionItemId: genericItem.genericSectionItemId,
      companyId: genericItem.companyId,
      genericSectionTypeId: genericItem.genericSectionTypeId,
      genericSectionName: genericItem.genericSectionName,
      title: genericItem.title,
      subtitle: genericItem.subtitle,
    };

    if (genericItem.genericSectionItemId === 0) await saveGenericItem(itemSave);
    else await updateGenericItem(itemSave);

    cleanFormSocialMedia();
  };

  const handleContactInfoDelete = (item) => {
    Swal.fire({
      title: "Estas a punto de eliminar el enlace",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const newContactInfos = socialMedia.filter((c) => c != item);
        setSocialMedia(newContactInfos);
        if (item.genericContactInfoId > 0) {
          useGenericSection
            .deleteContactInfo(item.genericContactInfoId)
            .then((res) => {
              return Swal.fire({
                text: "Información de contacto eliminada con éxito",
                icon: "success",
              });
            });
        }
      }
    });
  };

  const prepareUpdateContactInfo = (item) => {
    toggleSocialMedia()
    setSocialInfo(item);
    setCurrentItemSocialMedia(item.genericContactInfoId);
  }

  const handleRowSelect = async (gridItem) => {
    toggleTemplate("2");

    setGenericItem({
      genericSectionItemId: gridItem.genericSectionItemId,
      genericSectionTypeId: gridItem.genericSectionTypeId,
      genericSectionName: gridItem.genericSectionName,
      title: gridItem.title,
      subtitle: gridItem.subtitle,
      genericContactInfo: gridItem.genericContactInfo,
    });

    setSocialMedia(gridItem.genericContactInfo);

    if (gridItem.imageUrl) {
      const fileConverted = await fetch(gridItem.imageUrl)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], `img_${gridItem.genericSectionItemId}`, {
              type: "image/png",
            })
        );
      setLocalFiles([fileConverted]);
      setFilesSaved(gridItem.imageUrl);
    } else setLocalFiles([]);
  };

  const getGenericItemSections = () =>
    useGenericSection
      .getGenericItemSectionsByCompany()
      .then((sections) => setSections(sections));

  const getGenericItemContactTypes = () =>
    useGenericSection
      .getGenericItemContactTypes()
      .then((contactTypes) => setContactTypes(contactTypes));

  const handleOnNew = () => {
    toggleTemplate("2");
    clearObjGenericItem();
    setSocialMedia([]);
    setLocalFiles([]);
  };

  const handleOnCancel = () => {
    toggleTemplate("1");
    setSocialMedia([]);
    setLocalFiles([]);
    setBtnMainContact(false)
    setAddSocialMedia(false)
  };

  const handleAddContactInfo = () => {
    if (!socialInfo.contactValue) {
      return Swal.fire({
        text: "El valor del contacto es requerido",
        icon: "error",
      });
    }
    if (!socialInfo.contactTypeId) {
      return Swal.fire({
        text: "El tipo de contacto es requerido",
        icon: "error",
      });
    }

    if (!socialInfo.mainContact === socialMedia.length === 0) {
      return Swal.fire({
        text: "El primer contacto debe ser asignado como principal",
        icon: "error",
      });
    }

    if (socialInfo.mainContact) {
      if (socialInfo.genericContactInfoId > 0) {
        for (const c of socialMedia) {
          if (
            c.mainContact &&
            parseInt(c.genericContactInfoId) !==
              parseInt(socialInfo.genericContactInfoId)
          ) {
            return Swal.fire({
              text: "Sólo puedes seleccionar un contacto como principal",
              icon: "error",
            });
          }
        }
      } else {
        for (const c of socialMedia) {
          if (c.mainContact) {
            return Swal.fire({
              text: "Sólo puedes seleccionar un contacto como principal",
              icon: "error",
            });
          }
        }
      }
    }

    if (socialInfo.genericContactInfoId > 0) {
      for (const c of socialMedia) {
        if (
          socialInfo.contactValue == c.contactValue &&
          socialInfo.genericContactInfoId != c.genericContactInfoId
        ) {
          return Swal.fire({
            text: "Este contacto ya existe en la lista",
            icon: "error",
          });
        }
      }
    } else {
      for (const c of socialMedia) {
        if (socialInfo.contactValue == c.contactValue) {
          return Swal.fire({
            text: "Este contacto ya existe en la lista",
            icon: "error",
          });
        }
      }
    }

    if (socialInfo.genericContactInfoId > 0) {
      const index = socialMedia.findIndex(
        (c) =>
          parseInt(c.genericContactInfoId) ===
          parseInt(socialInfo.genericContactInfoId)
      );
      socialMedia[index] = socialInfo;
      setSocialMedia(socialMedia);
    } else setSocialMedia([...socialMedia, socialInfo]);

    toggleSocialMedia()
    setBtnMainContact(false)
    cleanFormSocialMedia()
  };

  const handleGenericItems = (genericSectionTypeId, genericSectionName) => {
    setSocialInfo({
      genericSectionTypeId: genericSectionTypeId,
      genericSectionName: genericSectionName,
    });
  };

  const handleCheckMain = (value) =>
    setSocialInfo({ ...socialInfo, mainContact: value });

  const handleCheckValue = (value) =>
    setSocialInfo({ ...socialInfo, showValue: value });

  const handleInputValue = (e) =>
    setSocialInfo({ ...socialInfo, [e.target.name]: e.target.value });

  const handleInputGeneric = (e) =>
    setGenericItem({ ...genericItem, [e.target.name]: e.target.value });

  const handleSelectTypeName = (value) => {
    const filtered = contactTypes.filter(
      (contactType) => contactType.contactTypeId === value
    );
    setSocialInfo({
      ...socialInfo,
      contactTypeName: filtered[0].name,
      contactTypeId: filtered[0].contactTypeId,
    });
  };

  const handleSelectSectionType = (value) => {
    const filtered = sections.filter(
      (item) => item.genericSectionTypeId === value
    );
    setGenericItem({
      ...genericItem,
      genericSectionTypeId: filtered[0].genericSectionTypeId,
      genericSectionName: filtered[0].sectionName,
    });
  };

  const handleSelectFile = (file) => {
    if (genericItem.genericSectionItemId !== 0) setLocalFilesUpdated(true);
    setLocalFiles(file);
  };

  const handleDeleteImage = () => setLocalFiles([]);

  const toggleSocialMedia = () => {
    if(addSocialMedia) {
      setAddSocialMedia(false);
      cleanFormSocialMedia();
    }
    else {
      setAddSocialMedia(true);
      setCurrentItemSocialMedia(0)
      if(socialMedia.length === 0) {
        setSocialInfo({...socialInfo, mainContact: true})
        setBtnMainContact(true)
      }
    }
  }

  const cleanFormSocialMedia = () => {
    clearObjSocialInfo();
    formSocialMedia.resetFields();
    setCurrentItemSocialMedia(0)
  }

  useEffect(() => {
    getGenericItemSections();
    getGenericItemContactTypes();
  }, []);

  console.log(socialInfo)
  return (
    <>
      <section className="dashboard-admin-container">
        <TitleAdmin
          title={"Publicaciones Genéricas"}
          subtitle={
            templateShow === "1"
              ? "Listado de publicaciones"
              : templateShow === "2"
              ? "Edición de publicación"
              : "Crear de publicación"
          }
          event={() =>
            templateShow === "1" ? history.push("/admin") : setTemplateShow("1")
          }
        />
        <HeadingAdmin
          text={templateShow === "1" ? "Agregar publicación" : "Cancelar"}
          icon={
            templateShow === "1" ? <PlusCircleOutlined /> : <FaTimesCircle />
          }
          event={() =>
            templateShow === "1" ? handleOnNew() : handleOnCancel()
          }
        />
        {
          {
            1: (
              <SearchAdminGenericSection
                sections={sections}
                handleRowSelect={handleRowSelect}
                ref={gridRef}
                handleGenericItems={handleGenericItems}
              />
            ),
            2: (
              <>
                <Form
                  className="form-backoffice"
                  onFinish={handleSubmit}
                  layout={"vertical"}
                  fields={[
                    {
                      name: ["title"],
                      value: genericItem.title,
                    },
                    {
                      name: ["subtitle"],
                      value: genericItem.subtitle,
                    },
                    {
                      name: ["contactValue"],
                      value: socialInfo.contactValue,
                    },
                    {
                      name: ["contactTypeId"],
                      value:
                        socialInfo.contactTypeId === 0
                          ? ""
                          : socialInfo.contactTypeId,
                    },
                    {
                      name: ["showValue"],
                      value: socialInfo.showValue,
                    },
                    {
                      name: ["genericSectionTypeId"],
                      value:
                        genericItem.genericSectionTypeId === 0
                          ? ""
                          : genericItem.genericSectionTypeId,
                    },
                  ]}
                >
                  <Row gutter={16}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={"Tipo de seccción"}
                        htmlFor={"genericSectionTypeId"}
                        name="genericSectionTypeId"
                        rules={[
                          {
                            required: true,
                            message: "*Campo Requerido",
                          },
                        ]}
                      >
                        <Select
                          name={"genericSectionTypeId"}
                          onChange={handleSelectSectionType}
                        >
                          {sections.map((item) => {
                            return (
                              <Select.Option
                                key={item.genericSectionTypeId}
                                value={item.genericSectionTypeId}
                              >
                                {item.sectionName}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={"Título de artículo"}
                        htmlFor={"title"}
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: "*Campo Requerido",
                          },
                          {
                            pattern: new RegExp(/^\S/),
                            message:
                              "No se aceptan espacios en blanco al inicio",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="title"
                          onChange={handleInputGeneric}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={"Subtítulo de artículo"}
                        htmlFor={"subtitle"}
                        name={"subtitle"}
                      >
                        <Input
                          type="text"
                          name="subtitle"
                          onChange={handleInputGeneric}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <InputFile
                        onFilesSelected={handleSelectFile}
                        width={720}
                        height={480}
                        edit={genericItem.genericSectionItemId > 0}
                        valueFile={localfiles}
                        nameFile={"localfiles"}
                        accept={["image"]}
                        deleteImage={handleDeleteImage}
                      />
                    </Col>
                    <Col xs={24}>
                      <div className="form-backoffice-footer">
                        <Button htmlType={"submit"} type="primary">
                          <p>Guardar</p>
                          <FaSave />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                {
                  (genericItem.genericSectionItemId > 0) && (
                        <>
                          <Row>
                            <Col xs={24}>
                              <Divider orientation={"left"}>
                                <h4>Redes sociales y enlaces</h4>
                              </Divider>
                            </Col>
                          </Row>
                          <Form
                              className="form-backoffice"
                              onFinish={handleSubmitSocialMedia}
                              form={formSocialMedia}
                              layout={"vertical"}
                              fields={[
                                {
                                  name: ["contactValue"],
                                  value: socialInfo.contactValue,
                                },
                                {
                                  name: ["contactTypeId"],
                                  value:
                                      socialInfo.contactTypeId === 0
                                          ? ""
                                          : socialInfo.contactTypeId,
                                },
                                {
                                  name: ["showValue"],
                                  value: socialInfo.showValue,
                                },
                                {
                                  name: ["genericSectionTypeId"],
                                  value:
                                      genericItem.genericSectionTypeId === 0
                                          ? ""
                                          : genericItem.genericSectionTypeId,
                                },
                              ]}
                          >
                            <Row gutter={[16,0]}>
                              <Col xs={24}>
                                <div className={'form-backoffice-footer'}>
                                  {
                                    (socialInfo.contactValue || socialInfo.contactTypeId) ? (
                                          <Button type={'primary'} htmlType={'button'} onClick={cleanFormSocialMedia}>
                                            <p>Resetear</p>
                                            <RiLoopLeftFill />
                                          </Button>
                                      ) : null
                                  }
                                  <Button type={'primary'} htmlType={'button'} onClick={toggleSocialMedia}>
                                    <p>{
                                      (addSocialMedia) ? 'Cancelar' : 'Agregar red social'
                                    }</p>
                                    {
                                      (addSocialMedia) ? <FaTimes /> : <PlusCircleOutlined />
                                    }
                                  </Button>
                                </div>
                              </Col>
                              {(addSocialMedia) && (
                                  <>
                                    <Col md={12} sm={24} xs={24}>
                                      <Form.Item
                                          label={"Valor"}
                                          htmlFor={"contactValue"}
                                          name="contactValue"
                                      >
                                        <Input
                                            type="text"
                                            name="contactValue"
                                            onChange={handleInputValue}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col md={12} sm={24} xs={24}>
                                      <Form.Item
                                          label={
                                              genericItem.contactTypeName || "Seleccionar tipo"
                                          }
                                          htmlFor={"contactTypeId"}
                                          name={"contactTypeId"}
                                      >
                                        <Select
                                            name={"contactTypeId"}
                                            onChange={handleSelectTypeName}
                                        >
                                          {contactTypes.map((contactType, index) => (
                                              <Select.Option
                                                  value={contactType.contactTypeId}
                                                  key={index}
                                              >
                                                {contactType.name}
                                              </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col lg={8} md={12} sm={24} xs={24}>
                                      <Form.Item
                                          label={"Es contacto principal"}
                                          htmlFor={"mainContact"}
                                          name={"mainContact"}
                                      >
                                        <Checkbox
                                            text={"Es contacto principal"}
                                            label={"mainContact"}
                                            disabled={btnMainContact}
                                            name={socialInfo.mainContact}
                                            handleValueCheck={handleCheckMain}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col lg={8} md={12} sm={24} xs={24}>
                                      <Form.Item
                                          label={"Mostrar sólo valor"}
                                          htmlFor={"showValue"}
                                          name={"showValue"}
                                      >
                                        <Checkbox
                                            text={"Mostrar sólo valor"}
                                            label={"showValue"}
                                            name={socialInfo.showValue}
                                            handleValueCheck={handleCheckValue}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                      <div className={'form-backoffice-footer'} style={{justifyContent: 'flex-start'}}>
                                        <Button
                                            type={"primary"}
                                            htmlType={"button"}
                                            onClick={handleAddContactInfo}
                                        >
                                          <p>
                                            {socialInfo.genericContactInfoId > 0
                                                ? "Actualizar"
                                                : "Agregar"}{" "}
                                            contacto
                                          </p>
                                          {socialInfo.genericContactInfoId > 0
                                              ? <FaChevronRight />
                                              : <PlusCircleOutlined />
                                          }
                                        </Button>
                                      </div>
                                    </Col>
                                  </>
                              )}
                            </Row>
                            <Row style={{marginTop: '1rem'}}>
                              <Col xs={24}>
                                <Divider orientation={'left'}>Lista de contactos</Divider>
                              </Col>
                              <Col xs={24}>
                                <SearchAdminContactInfos
                                    prepareUpdateContactInfo={
                                      prepareUpdateContactInfo
                                    }
                                    addSocialMedia={addSocialMedia}
                                    contactInfos={socialMedia}
                                    currentItemSocialMedia={currentItemSocialMedia}
                                    handleContactInfoDelete={handleContactInfoDelete}
                                    toggleSocialMedia={toggleSocialMedia}
                                />
                              </Col>
                              <Col xs={24}>
                                <div className={'form-backoffice-footer'}>
                                  <Button
                                      type={"primary"}
                                      htmlType={"submit"}
                                  >
                                    <p>
                                      Guardar
                                    </p>
                                    <FaSave />
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </>
                    )
                }
              </>
            ),
          }[templateShow]
        }
      </section>
    </>
  );
};
