import React, {useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import { userService } from "../../services/user.service";
import {IoCheckmarkCircle} from "react-icons/io5";
import {RegisterAside} from "../register/registerAside";
import {Col, Flex, Form, Input, Row, Button} from "antd";
import {RegisterHeader} from "../register/registerHeader";
import {SocialMedia} from "../register/socialMedia";
import {RegisterFooter} from "../register/registerFooter";
import "../register/register.scss";
// import {useForm} from "react-hook-form";

export function ResetPassword(props) {
  const [user, setUser] = useState({
    Email: '',
    Code: '',
    ConfirmPassword:'',
    password: '',
  });
  const history = useHistory();
  const [validatePassword, setValidatePassword] = useState(false);
  const oneCharacter = useRef();
  const oneN = useRef();
  const oneLetterL = useRef();
  const oneLetterU = useRef();
  const min8Characters = useRef();
  const [form] = Form.useForm();

  const onSubmit = () => {
    user.Email = props.location.search.split("email=")[1].split("&")[0];
    user.Code = props.location.search.split("code=")[1];
    user.ConfirmPassword = user.password;
    userService.resetPassword(user);
  };

  const handlePassword = (e) => {
    const oneLetterUppercase = /.*[A-Z].*/;
    const oneLetterLowercase = /.*[a-z].*/;
    const oneNumber = /.*[0-9].*/;
    const oneSpecialCharacter = /(.*[!@#$%\^&*(){}[\]<>?/|-]+|.*[!@#$%\^&*(){}[\]<>?/|\-]+)/;

    const noSpace = e.target.value.replace(/ /g,"");

    if(String(noSpace).length >= 8)
      min8Characters.current.className = 'success';
    else min8Characters.current.className = '';

    if(oneLetterUppercase.test(noSpace))
      oneLetterU.current.className = 'success';
    else oneLetterU.current.className = '';

    if(oneLetterLowercase.test(noSpace))
      oneLetterL.current.className = 'success'
    else oneLetterL.current.className = '';

    if(oneNumber.test(noSpace))
      oneN.current.className = 'success';
    else oneN.current.className = '';

    if(oneSpecialCharacter.test(noSpace))
      oneCharacter.current.className = 'success';
    else oneCharacter.current.className = '';

    form.setFieldValue('password',noSpace)
    setUser({...user, [e.target.name]: noSpace})
  }

  return (
      <section className="register__container">
        <article className="register__form">
          <div className={'register__form__container'}>
            <Flex gap={16} vertical className={'-h-100 -space-between -w-100'}>
              <RegisterHeader
                  title={'Quiero cambiar'}
                  subtitle={'mi contraseña'}
              />
              <Form
                  className="-form-light"
                  onFinish={onSubmit}
                  layout={'vertical'}
                  form={form}
              >
                <Row gutter={[32,0]}>
                  <Col xl={12} lg={12} md={24} sm={12} xs={24}>
                    <Form.Item
                        label={'Contraseña'}
                        htmlFor={'password'}
                        name={'password'}
                        rules={[
                          {
                            required: true,
                            message: '*Campo requerido'
                          },
                          {
                            min: 8,
                            message: ''
                          },
                          {
                            pattern: new RegExp(/.*[A-Z].*/),
                            message: ''
                          },
                          {
                            pattern: new RegExp(/.*[a-z].*/),
                            message: ''
                          },
                          {
                            pattern: new RegExp(/.*[0-9].*/),
                            message: ''
                          },
                          {
                            pattern: new RegExp(/(.*[!@#$%\^&*(){}[\]<>?/|-]+|.*[!@#$%\^&*(){}[\]<>?/|\-]+)/),
                            message: ''
                          },
                        ]}
                        onChange={handlePassword}
                    >
                      <Input.Password
                          name={'password'}
                          size={'large'}
                          onBlur={() => setValidatePassword(false)}
                          onFocus={() => setValidatePassword(true)}
                      />
                    </Form.Item>
                    <div className={`msg-password`}>
                      <ul className={'msg-password__list'}>
                        <li><span ref={min8Characters}><IoCheckmarkCircle /></span>Mínimo 8 carácteres</li>
                        <li><span ref={oneLetterU}><IoCheckmarkCircle /></span>1 letra mayúscula</li>
                        <li><span ref={oneLetterL}><IoCheckmarkCircle /></span>1 letra minúscula</li>
                        <li><span ref={oneN}><IoCheckmarkCircle /></span>1 número</li>
                        <li><span ref={oneCharacter}><IoCheckmarkCircle /></span>1 carácter especial</li>
                      </ul>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={12} xs={24}>
                    <Form.Item
                        label={'Confirma tu contraseña'}
                        name={'confirmPassword'}
                        rules={[
                          {
                            required: true,
                            message: ''
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('¡Las contraseñas no coinciden!'));
                            },
                          }),
                        ]}
                    >
                      <Input.Password
                          name={'confirmPassword'}
                          size={'large'}
                          disabled={user.password === ''}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="-form-light__footer">
                  <Button
                      htmlType={'button'}
                      className={'outline-dark'}
                      onClick={() => history.push('/')}
                  >
                    Regresar
                  </Button>
                  <Button htmlType={'submit'} type={'primary'}>Guardar contraseña</Button>
                </div>
              </Form>
              <SocialMedia />
              <RegisterFooter />
            </Flex>
          </div>
        </article>
        <RegisterAside />
      </section>
  );
}
